import React, { useMemo } from "react";
import { Row, Col, Grid, Space } from "antd";
import _ from "lodash";
import { useNavigate } from "react-router-dom/";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

import Theme from "../../core/Theme";

import Navbar from "../../components/Navbar";
import Footbar from "../../components/Footbar";
import styles from "./Gallery.module.css";
import PageTitle from "../../components/PageTitle";
import Tag from "../../components/Tag";

const { LIGHT_GREEN } = Theme;

const { useBreakpoint } = Grid;

const Gallery = () => {
  const breakpoint = useBreakpoint();

  return (
    <div>
      <Helmet>
        <title>Heaven Burns Me | Gallery</title>
      </Helmet>

      <Navbar />

      <div
        style={{
          marginTop: breakpoint?.md ? 80 : 40,
          marginBottom: breakpoint?.md ? 80 : 40,
          display: "grid",
        }}
        className="boxContainer"
      >
        <PageTitle title="Gallery" />

        <Row justify="center" style={{ color: LIGHT_GREEN }}>
          Commission / Friends Drawing
        </Row>
        <Illustration />
      </div>
      <Footbar />
    </div>
  );
};

const Illustration = () => {
  const breakpoint = useBreakpoint();
  const navigate = useNavigate();

  return (
    <div
      className="boxContainer"
      style={{ textAlign: "center", marginTop: breakpoint?.md ? 80 : 40 }}
    >
      <Space size={100} direction="vertical">
        {/* KT's */}
        <div>
          <motion.div
            style={{ position: "relative", zIndex: 2 }}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: [0, 1], scale: [1, 1.2, 1] }}
            transition={{
              duration: 0.8,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <img
              src="/images/illustration/ice19910.jpg"
              className={styles.illustration}
              style={{
                width: breakpoint?.md ? 400 : 350,
                height: breakpoint?.md ? 400 : 350,
              }}
              alt=""
            />
          </motion.div>
          <Row
            gutter={[8, 8]}
            justify="center"
            align="middle"
            style={{ marginTop: 16 }}
          >
            <Col span={!breakpoint?.md && 24}>
              <Tag type="friend" />
            </Col>
            <Col span={!breakpoint?.md && 24} className={styles.desc}>
              KT's Drawing
            </Col>
            <Col>
              <Tag type="twitter" redirect="https://twitter.com/ice19910" />
            </Col>
          </Row>
        </div>

        {/* Snowman's */}
        <div>
          <Row gutter={breakpoint?.lg ? 32 : [0, 32]}>
            <Col
              span={breakpoint?.lg ? 12 : 24}
              style={{ textAlign: breakpoint?.lg ? "right" : "center" }}
            >
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: [0, 1], scale: [1, 1.2, 1] }}
                transition={{
                  duration: 0.8,
                  delay: 0.5,
                  ease: [0.5, 0.71, 0.2, 1.01],
                }}
              >
                <img
                  src="/images/illustration/ig-snowman-draft.png"
                  className={styles.illustration}
                  style={{
                    width: breakpoint?.md ? 480 : 360,
                    height: breakpoint?.md ? 400 : 300,
                  }}
                  alt=""
                />
              </motion.div>
            </Col>
            <Col
              span={breakpoint?.lg ? 12 : 24}
              style={{ textAlign: breakpoint?.lg ? "left" : "center" }}
            >
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: [0, 1], scale: [1, 1.2, 1] }}
                transition={{
                  duration: 0.7,
                  delay: breakpoint?.lg ? 1.5 : 1,
                  ease: [0.5, 0.71, 0.2, 1.01],
                }}
              >
                <img
                  src="/images/illustration/ig-snowman.png"
                  className={styles.illustration}
                  style={{
                    width: breakpoint?.md ? 480 : 360,
                    height: breakpoint?.md ? 400 : 300,
                  }}
                  alt=""
                />
              </motion.div>
            </Col>
          </Row>
          <Row
            gutter={[8, 8]}
            justify="center"
            align="middle"
            style={{ marginTop: 16 }}
          >
            <Col span={!breakpoint?.md && 24}>
              <Tag type="commission" />
            </Col>
            <Col span={!breakpoint?.md && 24} className={styles.desc}>
              OneSnowyMan
            </Col>
          </Row>
        </div>

        {/* Miea's */}
        <div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.8,
            }}
          >
            <img
              src="/images/illustration/miea-savage-group.png"
              className={styles.illustration}
              style={{ width: "100%" }}
              alt=""
            />
          </motion.div>
          <Row
            gutter={[8, 8]}
            justify="center"
            align="middle"
            style={{ marginTop: 16 }}
          >
            <Col span={!breakpoint?.md && 24}>
              <Tag type="commission" />
            </Col>
            <Col span={!breakpoint?.md && 24} className={styles.desc}>
              Miea - 6.0 Savage Team
            </Col>
            <Col>
              <Tag type="twitter" redirect="https://twitter.com/miea222" />
            </Col>
            <Col>
              <Tag type="skeb" redirect="https://skeb.jp/@miea222" />
            </Col>
          </Row>
        </div>

        {/* Beluga's */}
        <div>
          <Row gutter={breakpoint?.lg ? 32 : [0, 32]}>
            <Col
              span={breakpoint?.lg ? 12 : 24}
              style={{ textAlign: breakpoint?.lg ? "right" : "center" }}
            >
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: [0, 1], scale: [1, 1.2, 1] }}
                transition={{
                  duration: 0.8,
                  delay: 0.5,
                  ease: [0.5, 0.71, 0.2, 1.01],
                }}
              >
                <img
                  src="/images/illustration/ig-beluga.jpg"
                  className={styles.illustration}
                  style={{
                    width: breakpoint?.md ? 400 : 350,
                    height: breakpoint?.md ? 400 : 350,
                  }}
                  alt=""
                />
              </motion.div>
            </Col>
            <Col
              span={breakpoint?.lg ? 12 : 24}
              style={{ textAlign: breakpoint?.lg ? "left" : "center" }}
            >
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: [0, 1], scale: [1, 1.2, 1] }}
                transition={{
                  duration: 0.8,
                  delay: 1.2,
                  ease: [0.5, 0.71, 0.2, 1.01],
                }}
              >
                <img
                  src="/images/illustration/ig-beluga-cute.jpg"
                  className={styles.illustration}
                  style={{
                    width: breakpoint?.md ? 400 : 350,
                    height: breakpoint?.md ? 400 : 350,
                  }}
                  alt=""
                />
              </motion.div>
            </Col>
          </Row>
          <Row
            gutter={[8, 8]}
            justify="center"
            align="middle"
            style={{ marginTop: 16 }}
          >
            <Col span={!breakpoint?.md && 24}>
              <Tag type="commission" />
            </Col>
            <Col span={!breakpoint?.md && 24} className={styles.desc}>
              白鯨 beluga
            </Col>
            <Col>
              <Tag
                type="ig"
                redirect="https://www.instagram.com/beluga_baak6king4/"
              />
            </Col>
          </Row>
        </div>

        {/* Quitterie's */}
        <div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              duration: 1,
              delay: 1.4,
            }}
          >
            <img
              src="/images/illustration/skeb-quitterie.png"
              className={styles.illustration}
              style={{
                width: breakpoint?.md ? 800 : "100%",
                height: breakpoint?.md ? 1263 : "auto",
              }}
              alt=""
            />
          </motion.div>
          <Row
            gutter={[8, 8]}
            justify="center"
            align="middle"
            style={{ marginTop: 16 }}
          >
            <Col span={!breakpoint?.md && 24}>
              <Tag type="commission" />
            </Col>
            <Col span={!breakpoint?.md && 24} className={styles.desc}>
              Quitterie
            </Col>
            <Col>
              <Tag
                type="twitter"
                redirect="https://twitter.com/Quitterie_ff14"
              />
            </Col>
            <Col>
              <Tag type="skeb" redirect="https://skeb.jp/@Quitterie" />
            </Col>
          </Row>
        </div>

        {/* Chii_nato's */}
        <div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: [0, 1], scale: [1, 1.2, 1] }}
            transition={{
              duration: 0.8,
              delay: 0.5,
              ease: [0.5, 0.71, 0.2, 1.01],
            }}
          >
            <img
              src="/images/illustration/skeb-chii_nato.png"
              className={styles.illustration}
              style={{
                width: breakpoint?.md ? 400 : 350,
                height: breakpoint?.md ? 400 : 350,
              }}
              alt=""
            />
          </motion.div>
          <Row
            gutter={[8, 8]}
            justify="center"
            align="middle"
            style={{ marginTop: 16 }}
          >
            <Col span={!breakpoint?.md && 24}>
              <Tag type="commission" />
            </Col>
            <Col span={!breakpoint?.md && 24} className={styles.desc}>
              クリームたっぷりのちい
            </Col>
            <Col>
              <Tag type="twitter" redirect="https://twitter.com/Chii_nato" />
            </Col>
            <Col>
              <Tag type="skeb" redirect="https://skeb.jp/@Chii_nato" />
            </Col>
          </Row>
        </div>
      </Space>
    </div>
  );
};

export default Gallery;
