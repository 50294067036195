import React from "react";
import { Row, Timeline, Grid } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import _ from "lodash";

import styles from "./Resume.module.css";

const { useBreakpoint } = Grid;

const Career = () => {
  const breakpoint = useBreakpoint();

  const timelineItems = () => {
    let items = [];

    _.map(career, (data) => {
      items.push({ children: <Item data={data} />, color: "#93C0BD" });
    });

    items.push({
      dot: <SmileOutlined />,
    });

    return items;
  };

  return (
    <div>
      <Row
        className={styles.sectionTitle}
        justify="center"
        style={{ marginBottom: 24 }}
      >
        Career Timeline
      </Row>

      <Timeline
        className={styles.timeline}
        mode={breakpoint?.md ? "alternate" : ""}
        items={timelineItems()}
      />
    </div>
  );
};

export default Career;

const Item = ({ data }) => {
  return (
    <div className={styles.educationItemWrapper}>
      <Row className={styles.educationYear}>{data?.year}</Row>
      <Row className={styles.educationTitle} style={{ marginTop: 4 }}>
        {data?.title}
      </Row>
      <Row className={styles.educationDesc} style={{ marginTop: 8 }}>
        {_.map(_.split(data.desc, "\n"), (desc) => {
          return <div key={`desc_${_.uniqueId()}`}>{desc}</div>;
        })}
      </Row>
    </div>
  );
};

const career = [
  {
    title: "Kano Technology",
    year: "2011-2017",
    desc: "Part time Web Programmer\nParticipated in development of commercial website and content management system",
  },
  {
    title: "Hong Kong Housing Authority",
    year: "2017-2019",
    desc: "Placement Programmer\nParticipated in development of a eForm system for site construction",
  },
  {
    title: "Technine Limited",
    year: "2019-2021",
    desc: "Programmer\nWorking on development of Frontend of Crypto Trading Platform, Backend for Crypto Wallet APP",
  },
];
