import React from "react";
import {
  SmileOutlined,
  MessageOutlined,
  TwitterOutlined,
  InstagramOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import styles from "./Tag.module.css";

const Tag = (props) => {
  const { type, redirect } = props;

  if (type === "friend") {
    return (
      <div className={`${styles.tag} ${styles.friendTag}`}>
        <SmileOutlined style={{ marginRight: 4 }} />
        Friends
      </div>
    );
  }

  if (type === "commission") {
    return (
      <div className={`${styles.tag} ${styles.commissionTag}`}>
        <MessageOutlined style={{ marginRight: 4 }} />
        Commission
      </div>
    );
  }

  if (type === "twitter") {
    return (
      <div
        onClick={() => {
          window.open(redirect, "_blank");
        }}
        className={`${styles.tag} ${styles.twitterTag}`}
      >
        <TwitterOutlined style={{ marginRight: 4 }} />
        Twitter
      </div>
    );
  }

  if (type === "ig") {
    return (
      <div
        onClick={() => {
          window.open(redirect, "_blank");
        }}
        className={`${styles.tag} ${styles.igTag}`}
      >
        <InstagramOutlined style={{ marginRight: 4 }} />
        IG
      </div>
    );
  }

  if (type === "skeb") {
    return (
      <div
        onClick={() => {
          window.open(redirect, "_blank");
        }}
        className={`${styles.tag} ${styles.skebTag}`}
      >
        <ProfileOutlined style={{ marginRight: 4 }} />
        Skeb
      </div>
    );
  }

  return null;
};

export default Tag;
