import { Row, Grid } from "antd";
import {
  GithubOutlined,
  LinkedinOutlined,
  GoogleOutlined,
  TwitterOutlined
} from "@ant-design/icons";
import _ from "lodash";
import styles from "./SocialMedia.module.css";
import SocialMediaBtn from "./SocialMediaBtn";

const { useBreakpoint } = Grid;

const data = [
  {
    name: "Twitter",
    link: "https://twitter.com/SoulCrusher_FF",
    className: styles.twitter,
    icon: <TwitterOutlined />
  },
  {
    name: "Github",
    link: "https://github.com/jackyngch",
    className: styles.github,
    icon: <GithubOutlined />
  },
  {
    name: "Linkedin",
    link: "https://www.linkedin.com/in/jacky-ng-bb99381a4/",
    className: styles.linkedin,
    icon: <LinkedinOutlined />
  },
  {
    name: "Gmail",
    link: "mailto:jacky84265@gmail.com",
    className: styles.gmail,
    icon: <GoogleOutlined />
  }
];

const SocialMediaGroup = (props) => {
  const { wrapperStyles = {} } = props;
  const breakpoint = useBreakpoint();

  return (
    <Row style={{ ...wrapperStyles, flexWrap: "nowrap" }} justify={breakpoint?.md ? "left" : "center"}>
      {_.map(data, (obj) => {
        return <SocialMediaBtn key={`social_btn_${obj?.name}`} data={obj} />;
      })}
    </Row>
  );
};

export default SocialMediaGroup;
