import React, { useRef } from "react";
import { Grid } from "antd";
import styles from "./Home.module.css";

import Navbar from "../components/Navbar";
import BannerSection from "../components/Home/BannerSection";
import InfoSection from "../components/Home/InfoSection";
import Footbar from "../components/Footbar";
import ThreeSignature from "../components/Three/ThreeSignature";
import Timer from "../components/Timer";

const { useBreakpoint } = Grid;

const Home = () => {
  const breakpoint = useBreakpoint();

  return (
    <div>
      <BannerSection />
      {breakpoint?.md && <ThreeSignature />}
      <InfoSection />
      <Timer />
      <Footbar />
    </div>
  );
};

export default Home;
