import * as THREE from "three";
import React, { useMemo, useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  useGLTF,
  Trail,
  Float,
  Line,
  Sphere,
  Stars,
  Environment,
  Lightformer,
  Clone,
  Text,
} from "@react-three/drei";
import { EffectComposer, Bloom } from "@react-three/postprocessing";

const ThreeSignature = () => {
  return (
    <Canvas camera={{ position: [20, 0, 40], fov: 9 }}>
      <color attach="background" args={["#03B89F"]} />
      <Float
        position={[-1, 0.1, 0]}
        speed={2}
        rotationIntensity={1}
        floatIntensity={2.5}
      >
        <Atom />
      </Float>
      <Float
        position={[5, -2.5, 0]}
        speed={3}
        rotationIntensity={0.8}
        floatIntensity={1.5}
      >
        <Scene />
      </Float>
      <Text
        position={[-3, 0, 0]}
        font="/3d/Ki-Medium.ttf"
        fontSize={5}
        material-toneMapped={false}
        anchorX="center"
        anchorY="middle"
      >
        {`JACKY    NG`}
      </Text>
      <ambientLight intensity={0.5} />
      <directionalLight
        position={[-10, 10, 5]}
        shadow-mapSize={[256, 256]}
        shadow-bias={-0.0001}
        castShadow
      >
        <orthographicCamera attach="shadow-camera" args={[-10, 10, -10, 10]} />
      </directionalLight>
      <Environment resolution={32}>
        <Lightformer position={[10, 10, 10]} scale={10} intensity={4} />
        <Lightformer
          position={[10, 0, -10]}
          scale={10}
          color="green"
          intensity={6}
        />
        <Lightformer position={[-10, -10, -10]} scale={10} intensity={4} />
      </Environment>
      <Stars saturation={0} count={400} speed={0.5} />
      <EffectComposer>
        <Bloom mipmapBlur luminanceThreshold={1} radius={0.7} />
      </EffectComposer>
    </Canvas>
  );
};

export default ThreeSignature;

const Atom = (props) => {
  const points = useMemo(
    () =>
      new THREE.EllipseCurve(0, 0, 3, 1.15, 0, 2 * Math.PI, false, 0).getPoints(
        100
      ),
    []
  );
  return (
    <group {...props}>
      <Line worldUnits points={points} color="turquoise" lineWidth={0.3} />
      <Line
        worldUnits
        points={points}
        color="turquoise"
        lineWidth={0.3}
        rotation={[0, 0, 1]}
      />
      <Line
        worldUnits
        points={points}
        color="turquoise"
        lineWidth={0.3}
        rotation={[0, 0, -1]}
      />
      <Electron position={[0, 0, 0.5]} speed={4} />
      <Electron
        position={[0, 0, 0.5]}
        rotation={[0, 0, Math.PI / 3]}
        speed={2}
      />
      <Electron
        position={[0, 0, 0.5]}
        rotation={[0, 0, -Math.PI / 3]}
        speed={3}
      />
      <Sphere args={[0.55, 64, 64]}>
        <meshBasicMaterial color={[0.5, 2, 5]} toneMapped={false} />
      </Sphere>
    </group>
  );
};

const Electron = ({ radius = 2.75, speed = 6, ...props }) => {
  const ref = useRef();
  useFrame((state) => {
    const t = state.clock.getElapsedTime() * speed;
    ref.current.position.set(
      Math.sin(t) * radius,
      (Math.cos(t) * radius * Math.atan(t)) / Math.PI / 1.25,
      0
    );
  });
  return (
    <group {...props}>
      <Trail
        local
        width={5}
        length={6}
        color={new THREE.Color(2, 5, 5)}
        attenuation={(t) => t * t}
      >
        <mesh ref={ref}>
          <sphereGeometry args={[0.25]} />
          <meshBasicMaterial color={[1, 6, 6]} toneMapped={false} />
        </mesh>
      </Trail>
    </group>
  );
};

const Scene = (props) => {
  const { scene } = useGLTF("/3d/gigantamax_pikachu.glb");

  return (
    <group {...props}>
      <Clone object={scene} scale={[0.03, 0.03, 0.03]} rotation={[0, 0.1, 0]} />
    </group>
  );
};
