import React from "react";
import { Row, Col, Grid } from "antd";
import styles from "./Blocks.module.css";
import _ from "lodash";

import BlockCard from "./BlockCard";

const { useBreakpoint } = Grid;

const BlockList = (props) => {
  const { data, type } = props;
  const breakpoint = useBreakpoint();

  return (
    <Row justify="center" gutter={[32, 0]}>
      <Col span={24}>
        <Row className={styles.blockId} style={{ color: "white", marginBottom: 8, paddingLeft: 12 }}>
          {type}
        </Row>
        <Row gutter={breakpoint?.md ? [24, 16] : [0, 16]} style={{ width: "100%", marginLeft: 0, marginRight: 0 }}>
          {_.map(_.range(0, 10), (i) => {
            if (data[i]) {
              return <BlockCard key={data[i].name} order={i} data={data[i]} type={type} />;
            }
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default BlockList;
