import React from "react";
import { Row, Col, Grid } from "antd";
import _ from "lodash";

const { useBreakpoint } = Grid;

const skills = [
  "html5",
  "css",
  "js",
  "react",
  "redux",
  "ant-design",
  "node",
  "java",
  "php",
  "sql",
  "github",
  "gitlab",
  "postman",
  "figma",
  "ps",
];

const ProgrammingSkills = (props) => {
  const { imgWidth = 80, imgHeight = 80, centered = false } = props;
  const breakpoint = useBreakpoint();

  return (
    <Row
      gutter={[breakpoint?.md ? 24 : 8, breakpoint?.md ? 16 : 8]}
      justify={!breakpoint?.md || centered ? "center" : ""}
      align="middle"
      style={{ paddingLeft: 12, paddingRight: 12 }}
    >
      {_.map(skills, (skill) => {
        return (
          <Col key={`skill_image_${skill}`}>
            <img
              src={`/images/programming/${skill}.svg`}
              alt=""
              style={{
                width: imgWidth,
                height: imgHeight,
              }}
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default ProgrammingSkills;
