import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import _ from "lodash";
import styles from "./Timer.module.css";

dayjs.extend(duration);

const START_DATE = dayjs("2021-10-25 10:30:00");

const Timer = () => {
  const [diff, setDiff] = useState(dayjs.duration(dayjs().diff(START_DATE)));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDiff(dayjs.duration(dayjs().diff(START_DATE)));
    }, 1000);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  return (
    <div className={styles.container}>
      <Row className={styles.title} justify="center">
        HOW LONG I WORKED?
      </Row>
      <Row className={styles.countdown} justify="center" gutter={[24, 0]}>
        <Col>
          <Row justify="center">
            <span>{diff.years()}</span>
          </Row>
          <Row justify="center">Years</Row>
        </Col>
        <Col>
          <Row justify="center">
            <span>{diff.months()}</span>
          </Row>
          <Row justify="center">Months</Row>
        </Col>
        <Col>
          <Row justify="center">
            <span>{diff.days()}</span>
          </Row>
          <Row justify="center">Days</Row>
        </Col>
        <Col>
          <Row justify="center">
            <span>{diff.hours()}</span>
          </Row>
          <Row justify="center">Hours</Row>
        </Col>
        <Col>
          <Row justify="center">
            <span>{diff.minutes()}</span>
          </Row>
          <Row justify="center">Minutes</Row>
        </Col>
        <Col>
          <Row justify="center">
            <span>{diff.seconds()}</span>
          </Row>
          <Row justify="center">Seconds</Row>
        </Col>
      </Row>
    </div>
  );
};

export default Timer;
