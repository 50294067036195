import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Grid,
  Layout,
  Divider,
  Typography,
  Collapse,
  Space,
} from "antd";
import styles from "../pages/Home.module.css";
import Theme from "../core/Theme";
import dayjs from "dayjs";
import _ from "lodash";
import SocialMediaGroup from "./SocialMedia/SocialMediaGroup";

const { DEFAULT_BROWN, LIGHT_BROWN, LIGHT_ORANGE, DARK_GREEN } = Theme;
const { useBreakpoint } = Grid;
const { Footer } = Layout;
const { Panel } = Collapse;

const items = [
  {
    title: "",
    links: [],
  },
  {
    title: "",
    links: [],
  },
  {
    title: "",
    links: [],
  },
  {
    title: "Resources",
    links: [
      { name: "Gallery", path: "gallery" },
      { name: "Resume", path: "resume" },
    ],
  },
];

const Footbar = () => {
  const breakpoint = useBreakpoint();
  const navigate = useNavigate();

  return (
    <Footer style={{ background: LIGHT_ORANGE }}>
      <Row
        gutter={[32, breakpoint?.md ? 0 : 16]}
        className={`boxContainer ${styles.footerContainer}`}
        style={{ marginLeft: "auto", marginRight: "auto" }}
      >
        <Col
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={8}
          style={{ minWidth: "min-content", paddingLeft: 0, paddingRight: 0 }}
        >
          <Col
            span={24}
            style={{
              marginTop: 8,
              paddingLeft: 0,
              paddingRight: 0,
              textAlign: breakpoint?.md ? "left" : "center",
            }}
          >
            <img
              src="/images/illustration/mukuchi-icon.png"
              alt=""
              onClick={() => {
                navigate("/");
              }}
              style={{
                width: breakpoint?.md ? 120 : 90,
                height: breakpoint?.md ? 120 : 90,
                cursor: "pointer",
              }}
            />
          </Col>

          <Col
            span={24}
            style={{
              marginTop: 16,
              marginBottom: breakpoint?.md ? 16 : 0,
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <SocialMediaGroup />
          </Col>
        </Col>

        {/* Collapse */}
        <Col
          flex={1}
          style={{
            paddingLeft: breakpoint?.md ? "" : 0,
            paddingRight: breakpoint?.md ? "" : 0,
          }}
        >
          <Row gutter={breakpoint?.md ? [32, 16] : [0, 16]}>
            {_.map(items, (item, i) => {
              const showSpace = breakpoint?.md ? true : item.links.length > 0;
              return (
                showSpace && (
                  <Col key={"col_" + i} xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Space direction="vertical">
                      <Typography.Text
                        style={{
                          color: DARK_GREEN,
                          fontSize: 16,
                          marginBottom: 15,
                        }}
                      >
                        {item.title}
                      </Typography.Text>
                      {_.map(item.links, ({ name, path }) => (
                        <Link key={"link_" + name} to={`/${path}`}>
                          <Typography.Text
                            className="footer-items"
                            style={{ fontSize: 12 }}
                          >
                            {name}
                          </Typography.Text>
                        </Link>
                      ))}
                    </Space>
                  </Col>
                )
              );
            })}
          </Row>
        </Col>

        <Divider
          style={{
            background: DEFAULT_BROWN,
            height: "1px",
            margin: breakpoint?.md ? "16px 0" : "0px 0",
          }}
        />
        <Row
          justify="start"
          className={`boxContainer ${styles.footerContainer}`}
          style={{ width: "100%", marginTop: breakpoint?.md ? 16 : 8 }}
        >
          <Typography.Text
            style={{
              width: "100%",
              fontSize: 12,
              color: LIGHT_BROWN,
            }}
          >
            {`JACKY NG © ${dayjs().year()}`}
          </Typography.Text>
        </Row>
      </Row>
    </Footer>
  );
};

export default Footbar;
