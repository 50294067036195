import React from "react";
import { Row, Timeline, Grid } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import _ from "lodash";

import styles from "./Resume.module.css";

const { useBreakpoint } = Grid;

const Education = () => {
  const breakpoint = useBreakpoint();

  const timelineItems = () => {
    let items = [];

    _.map(educations, (data) => {
      items.push({ children: <Item data={data} />, color: "#93C0BD" });
    });

    items.push({
      dot: <SmileOutlined />,
    });

    return items;
  };

  return (
    <div>
      <Row
        className={styles.sectionTitle}
        justify="center"
        style={{ marginBottom: 24 }}
      >
        Education Timeline
      </Row>

      <Timeline
        className={styles.timeline}
        mode={breakpoint?.md ? "alternate" : ""}
        items={timelineItems()}
      />
    </div>
  );
};

export default Education;

const Item = ({ data }) => {
  return (
    <div className={styles.educationItemWrapper}>
      <Row className={styles.educationYear}>{data?.year}</Row>
      <Row className={styles.educationTitle} style={{ marginTop: 4 }}>
        {data?.title}
      </Row>
      <Row className={styles.educationDesc} style={{ marginTop: 8 }}>
        {_.map(_.split(data.desc, "\n"), (desc) => {
          return <div key={`desc_${_.uniqueId()}`}>{desc}</div>;
        })}
      </Row>
    </div>
  );
};

const educations = [
  {
    title: "Cheung Sha Wan Catholic Seconday School",
    year: "2011-2017",
    desc: "Secondary School (HKDSE)\nAttained 4 in ICT and 3 in English, Chinese, Mathematics and Biology",
  },
  {
    title: "Institution of Vocational Education (Tsing Yi)",
    year: "2017-2019",
    desc: "Higher Diploma in Software Engineering\n3.79/4.0 GPA, Graduated with Distinction",
  },
  {
    title: "City University of Hong Kong",
    year: "2019-2021",
    desc: "BSc Computer Science\n3.05/4.3 GPA, Awarded with Upper Second Class Honors",
  },
];
