import React from "react";
import { Grid, Row, Col, Space } from "antd";
import styles from "./Blocks.module.css";
import { motion } from "framer-motion";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";

import { toDecimalNumberString } from "../../core/Helper";

dayjs.extend(relativeTime);

const { useBreakpoint } = Grid;

const BlockCard = (props) => {
  const { order, data, type } = props;
  const breakpoint = useBreakpoint();

  return (
    <Col span={breakpoint?.md ? (type === "Games" ? 12 : 8) : 24}>
      <motion.div
        className={styles.cardBg}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.1 + order / 20,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col>
            <div className={styles.tag} />
          </Col>
          <Col className={styles.cardInfo}>
            <Row style={{ width: "100%", flexWrap: "nowrap" }} align="middle">
              <Col className={styles.title}>
                <Row align="middle">
                  {data?.icon && (
                    <img
                      src={data?.icon}
                      className={`${styles.icon} ${
                        type === "Games" ? styles.gameIcon : styles.smIcon
                      }`}
                      alt=""
                    />
                  )}
                  {data?.name}
                </Row>
              </Col>
              {data?.desc && (
                <>
                  <Col className={styles.rowSeparator}>
                    <div className={styles.separator} />
                  </Col>
                  <Col>
                    <Space size={12}>
                      <div className={styles.desc}>{data?.desc}</div>
                    </Space>
                  </Col>
                </>
              )}
            </Row>
            <Row
              align="middle"
              style={{
                flexWrap: breakpoint?.md ? "nowrap" : "",
                marginTop: breakpoint?.md ? 8 : 4,
              }}
            >
              <Col
                span={breakpoint?.md ? 12 : 24}
                style={{
                  textAlign: breakpoint?.md ? "" : "left",
                  marginTop: breakpoint?.md ? 0 : 4,
                  marginBottom: breakpoint?.md ? 0 : 4,
                }}
              >
                <Space size={4}>
                  <div className={styles.idLabel}>{data?.id && "ID:"}</div>
                  <div
                    className={`${styles.id} ${data?.redirect !== undefined && styles.activeId}`}
                    onClick={() => {
                      if (data?.redirect !== undefined) {
                        window.open(data?.redirect, "_blank");
                      }
                    }}
                  >
                    {data?.id}
                  </div>
                </Space>
              </Col>
              {data?.joinDate && (
                <Col
                  span={breakpoint?.md ? 12 : 24}
                  className={styles.dateTime}
                >
                  <Space size={8} style={{ whiteSpace: "nowrap" }}>
                    <div>From</div>
                    <div>{dayjs.unix(data?.joinDate).format("YYYY/MM")}</div>
                    <div className={styles.dateTimeSeparator} />
                    <div>{dayjs.unix(data.joinDate).fromNow()}</div>
                  </Space>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </motion.div>
    </Col>
  );
};

export default BlockCard;
