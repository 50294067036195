import React, { Component, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { message } from "antd";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Gallery from "./pages/Gallery";
import Resume from "./pages/Resume";
import ScrollToTop from "./components/ScrollToTop";

message.config({
  top: "50%",
  maxCount: 1,
});

const AppContainer = (props) => {
  return (
    <div className="wrapper">
      <BrowserRouter>
      <ScrollToTop />

        <Routes>
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AppContainer;
