export default {
  GENERAL_GREEN: "#03B89F",
  ERROR_RED: "#F55357",
  GENERAL_ORANGE: "#FBA500",
  STROKE_GREY: "#3A444F",
  DIM_GREY: "#788A9F",
  DEEP_GREY: "#8494A8",
  GREY_WHITE: "#272A2E",
  FONT_BLACK: "#fff",

  CONTENT_1: "#282838",
  CONTENT_2: "#827F9E",
  GREY_1: "#F6F6F9",
  GREY_2: "#A3A3B1",
  GREY_3: "#3E4E6C",

  LIGHT_ORANGE: "#FFF6E9",
  DEFAULT_BROWN: "#E7DAC6",
  LIGHT_BROWN: "#D3BFA7",
  MIDDLE_BROWN: "#BDA486",
  DEEP_BROWN: "#9F8B73",
  DIM_GREEN: "#BDE3E1",
  LIGHT_GREEN: "#93C0BD",
  DARK_GREEN: "#517371",
  NAVBAR_GREEN: "#394F51",
};
