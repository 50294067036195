import React from "react";
import { Layout, Row } from "antd";
import { useNavigate } from "react-router-dom";
import Theme from "../core/Theme";

const { Header } = Layout;

const { NAVBAR_GREEN } = Theme;

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <Header
      className="navbar-header"
      style={{ background: NAVBAR_GREEN, height: 80 }}
    >
      <Row
        align="middle"
        className="boxContainer navbarContainer"
        style={{ marginLeft: "auto", marginRight: "auto" }}
      >
        <Row onClick={() => navigate("/")} style={{cursor: "pointer"}}>
          <img
            className="navbarIcon"
            src="/images/illustration/mukuchi-icon-light.png"
            alt=""
            style={{ width: 70, height: 70, marginTop: 5 }}
            onClick={() => {
              navigate("/");
            }}
          />
          <div
            style={{
              marginTop: 10,
              marginLeft: 10,
              fontSize: 20,
              fontWeight: 700,
              color: "white",
              letterSpacing: 2,
            }}
          >
            Heaven Burns Me
          </div>
        </Row>
      </Row>
    </Header>
  );
};

export default Navbar;
