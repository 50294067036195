import React, { useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import { ParallaxProvider } from "react-scroll-parallax";
import "./style.css";

import AppContainer from "./AppContainer";

const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Poppins",
        },
      }}
    >
      <ParallaxProvider>
        <AppContainer />
      </ParallaxProvider>
    </ConfigProvider>
  );
};

export default App;
