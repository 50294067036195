import React from "react";
import { DownCircleOutlined } from "@ant-design/icons";
import { Row, Col, Grid } from "antd";
import { motion } from "framer-motion";
import styles from "../../pages/Home.module.css";
import Theme from "../../core/Theme";
import { Parallax } from "react-scroll-parallax";

const { LIGHT_GREEN, MIDDLE_BROWN } = Theme;
const { useBreakpoint } = Grid;

const BannerSection = (props) => {
  const breakpoint = useBreakpoint();

  return (
    <div
      className={styles.banner}
      style={{ height: "100vh", overflow: "hidden", position: "relative" }}
    >
      <div className="boxContainer">
        <Row
          style={{
            flexWrap: breakpoint?.md ? "nowrap" : "",
            marginLeft: 16,
            marginRight: 16
          }}
          align="middle"
        >
          <Col
            span={breakpoint?.md ? 11 : 24}
            style={{
              textAlign: "center",
              display: "grid",
              placeContent: "center",
              marginTop: breakpoint?.md ? "" : 78
            }}
          >
            <div style={{ maxWidth: 340 }}>
              <Row justify="center">
                <motion.div
                  className={styles.cardBg}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 1,
                    delay: 0.5,
                    ease: [0, 0.71, 0.2, 1.01]
                  }}
                >
                  <img
                    src="/images/home/logo.png"
                    alt=""
                    style={{
                      width: breakpoint?.md ? 221 : 136,
                      height: breakpoint?.md ? 148 : 91
                    }}
                  />
                </motion.div>
              </Row>
              <Row
                style={{
                  color: LIGHT_GREEN,
                  fontSize: breakpoint?.md ? 16 : 14
                }}
                justify="center"
              >
                <motion.div
                  className={styles.cardBg}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 1,
                    delay: 0.5,
                    ease: [0, 0.71, 0.2, 1.01]
                  }}
                >
                  HEAVEN BURNS ME
                </motion.div>
              </Row>

              <Row style={{ marginTop: 16 }} justify="center">
                <div className={styles.separator} />
              </Row>
              <Row
                style={{
                  color: LIGHT_GREEN,
                  marginTop: 16,
                  fontSize: breakpoint?.md ? 16 : 14
                }}
                justify="center"
              >
                <motion.div
                  className={styles.cardBg}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 1,
                    delay: 1
                  }}
                >
                  Welcome to my page!
                  <br />
                  I am
                  <br />
                  Jacky Ng/SoulCrusher/JackySC
                </motion.div>
              </Row>
            </div>

            <motion.div
              className={styles.cardBg}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 1,
                delay: 2
              }}
              style={{ marginTop: breakpoint?.md ? 40 : 28, maxWidth: 340 }}
            >
              <Row
                style={{
                  color: MIDDLE_BROWN,
                  fontSize: breakpoint?.md ? 28 : 24
                }}
                justify="center"
              >
                WHO AM I?
              </Row>
            </motion.div>

            <motion.div
              className={styles.cardBg}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 1,
                delay: 3
              }}
              style={{ marginTop: breakpoint?.md ? 40 : 28, maxWidth: 340 }}
            >
              <Row
                style={{
                  color: LIGHT_GREEN,
                  fontSize: breakpoint?.md ? 16 : 14
                }}
                justify="center"
              >
                I am a Programmer IRL.
                {' '}
                <br />
                I am also a player of:
              </Row>
              <Row
                style={{
                  color: LIGHT_GREEN,
                  marginTop: breakpoint?.md ? 24 : 16,
                  fontSize: breakpoint?.md ? 18 : 16,
                  fontWeight: "bold"
                }}
                justify="center"
              >
                Granblue Fantasy, Heaven Burns Red, Final Fantasy XIV
              </Row>
            </motion.div>
          </Col>

          {breakpoint?.md ? (
            <Col span={13} className={styles.bannerPhoneSection}>
              <Parallax
                speed={!breakpoint?.xl ? 0 : 40}
                translateY={[!breakpoint?.xl ? 0 : 5, !breakpoint?.xl ? 0 : -5]}
              >
                <img
                  className={styles.bannerPhoneImg}
                  // src={`/images/home/banner-phone${breakpoint?.xl ? "s" : ""}.png`}
                  src={`/images/${breakpoint?.xl ? "illustration/skeb-quitterie" : "home/banner-phone"}.png`}
                  alt=""
                />
              </Parallax>
            </Col>
          ) : (
            <Col
              span={24}
              style={{
                marginTop: breakpoint?.md ? 32 : 24,
                textAlign: "center"
              }}
            >
              <Parallax speed={20} translateY={[10, -10]}>
                <img
                  className={styles.bannerPhoneImg}
                  src="/images/home/banner-phone.png"
                  alt=""
                  style={{
                    width: 212,
                    height: 458
                  }}
                />
              </Parallax>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default BannerSection;
