import React, { useState } from "react";
import { Row, Col, Grid, Space, Button, message, Steps } from "antd";
import {
  ReadOutlined,
  SolutionOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import { useNavigate } from "react-router-dom/";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

import Theme from "../../core/Theme";

import Navbar from "../../components/Navbar";
import Footbar from "../../components/Footbar";
import styles from "./Resume.module.css";
import PageTitle from "../../components/PageTitle";
import Tag from "../../components/Tag";
import ProgrammingSkills from "../../components/ProgrammingSkills";
import Education from "./Education";
import Career from "./Career";
import Current from "./Current";

const { LIGHT_GREEN } = Theme;

const { useBreakpoint } = Grid;

const Resume = () => {
  const breakpoint = useBreakpoint();
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
  }));

  return (
    <div>
      <Helmet>
        <title>Heaven Burns Me | Resume</title>
      </Helmet>

      <Navbar />

      <div
        style={{
          marginTop: breakpoint?.md ? 80 : 40,
          marginBottom: breakpoint?.md ? 80 : 40,
          display: "grid",
        }}
        className="boxContainer"
      >
        <PageTitle title="Resume" hideIcon />

        <Row justify="center" style={{ color: LIGHT_GREEN }}>
          My Career
        </Row>

        <div style={{ marginTop: 40 }}>
          <Steps
            className={styles.step}
            current={current}
            items={items}
            style={{ maxWidth: 1216, marginLeft: "auto", marginRight: "auto" }}
            onChange={(value) => {
              setCurrent(value);
            }}
          />

          <div
            className={styles.contentContainer}
            style={{ marginTop: breakpoint?.md ? 24 : 0 }}
          >
            {steps[current].content}
          </div>

          <div
            style={{
              marginTop: 24,
              maxWidth: 1216,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => prev()}
              disabled={current === 0}
            >
              Previous
            </Button>
            <Button
              type="primary"
              onClick={() => next()}
              disabled={current === steps.length - 1}
            >
              Next
            </Button>
          </div>
        </div>

        <Row justify="center" style={{ marginTop: 64 }}>
          <ProgrammingSkills imgWidth={100} imgHeight={100} centered />
        </Row>
      </div>
      <Footbar />
    </div>
  );
};

export default Resume;

const steps = [
  {
    title: "Education",
    content: <Education />,
    icon: <ReadOutlined style={{ marginTop: 3 }} />,
  },
  {
    title: "Career",
    content: <Career />,
    icon: <SolutionOutlined style={{ marginTop: 3 }} />,
  },
  {
    title: "Current",
    content: <Current />,
    icon: <IdcardOutlined style={{ marginTop: 3 }} />,
  },
];
