import React, { useState } from "react";
import { Row, Grid } from "antd";
import styles from "../../pages/Home.module.css";
import _ from "lodash";
import BlockList from "../Blocks/BlockList";
import ProgrammingSkills from "../ProgrammingSkills";

const { useBreakpoint } = Grid;

const type = ["Games", "Social Media"];

const gameData = [
  {
    name: "Granblue Fantasy",
    id: "10360400",
    desc: "",
    icon: "/images/game-icon/gbf-icon.png",
    joinDate: 1457654400
  },
  {
    name: "Heaven Burns Red",
    id: "",
    icon: "/images/game-icon/hbr-icon.png",
    joinDate: 1672185600
  },
  {
    name: "Final Fantasy 14",
    id: "Soul Crusher",
    icon: "/images/game-icon/ff14-icon.png",
    desc: "Gaia - Ifrit",
    joinDate: 1590624000
  },
  {
    name: "Blue Archive",
    id: "BFXCNLVD",
    icon: "/images/game-icon/ba-icon.png",
    desc: "JP Server",
    joinDate: 1673365942
  }
];

const mediaData = [
  {
    name: "Discord",
    id: "jackyngsc",
    icon: "/images/social-media/discord.png"
  },
  {
    name: "Twitter",
    id: "@SoulCrusher_FF",
    redirect: "https://twitter.com/SoulCrusher_FF",
    icon: "/images/social-media/twitter.png"
  },
  {
    name: "Twitter (RT/HBR)",
    id: "@SoulCrusherRT",
    redirect: "https://twitter.com/SoulCrusherRT",
    icon: "/images/social-media/twitter.png"
  },
  {
    name: "Steam",
    id: "SoulCrusher",
    redirect: "https://steamcommunity.com/profiles/76561198070254418/",
    icon: "/images/social-media/steam.png"
  },
  {
    name: "Youtube",
    id: "SoulCrusher",
    redirect: "https://www.youtube.com/@jackysc",
    icon: "/images/social-media/youtube.png"
  }
];

const InfoSection = () => {
  const breakpoint = useBreakpoint();

  return (
    <div
      className={styles.carouselBg}
      style={{
        paddingTop: breakpoint?.lg ? 64 : 32,
        paddingBottom: breakpoint?.lg ? 64 : 32
      }}
    >
      <Row>
        <div className="boxContainer" style={{ width: "100%" }}>
          <BlockList type={type[0]} data={gameData} />
        </div>
      </Row>

      <Row style={{ marginTop: 32 }}>
        <div className="boxContainer" style={{ width: "100%" }}>
          <BlockList type={type[1]} data={mediaData} />
        </div>
      </Row>

      <Row style={{ marginTop: 32 }}>
        <div className="boxContainer" style={{ width: "100%" }}>
        <Row className={styles.blockId} style={{ color: "white", marginBottom: 8, paddingLeft: 12 }}>
          Programming
        </Row>
        <ProgrammingSkills />
        </div>
      </Row>
    </div>
  );
};

export default InfoSection;
