import React from "react";
import { Row } from "antd";

const Current = () => {
  return (
    <div>
      <Row>Position</Row>
      <Row>Language using</Row>
      <Row>Projects</Row>
    </div>
  );
};

export default Current;
