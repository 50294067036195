import { Col } from "antd";
import styles from "./SocialMedia.module.css";

const SocialMediaBtn = (props) => {
  const {
    name, link, className, icon
  } = props?.data;

  const clickToRedirect = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <Col onClick={() => clickToRedirect(link)}>
      <div className={`${styles.icon} ${className}`}>
        <span className={styles.tooltip}>{name}</span>
        <span>{icon}</span>
      </div>
    </Col>
  );
};

export default SocialMediaBtn;
