import React from "react";
import { Row, Grid } from "antd";

const { useBreakpoint } = Grid;

const PageTitle = (props) => {
  const { title, hideIcon = false } = props;
  const breakpoint = useBreakpoint();

  return (
    <div>
      {!hideIcon && (
        <Row justify="center">
          <img
            src="/images/illustration/mukuchi-icon.png"
            alt=""
            style={{
              width: breakpoint?.md ? 221 : 136,
              height: breakpoint?.md ? 221 : 136,
            }}
          />
        </Row>
      )}
      <Row
        justify="center"
        className="pageTitle"
        style={{ textAlign: "center" }}
      >
        {title || ""}
      </Row>
    </div>
  );
};

export default PageTitle;
